/* Global Styling ---------------------------------------*/
body {
    margin: 0;
    padding: 0;
    font-family: 'Raleway', sans-serif;
    background-color: whitesmoke;
}

#root {
    height: 100vh;
}

#screenSize {
    height: 100%;
}

/* Navigation Bar Styling Here ----------------------------*/
#navBar {
    display: flex;
    background-color: rgb(5, 5, 5);
    align-items: center;
    width: 100%;
    height: 08%;
    font-size: 120%;
}

#lastName {
    color: rgb(49, 204, 189);
    text-decoration: none;
    transition-duration: 0.4s
}

#lastName:hover {
    color: whitesmoke;
    text-decoration: none;
    transition-duration: 0.4s
}

.navBarHomeText {
    color: rgb(49, 204, 189);
    text-decoration: none;
    font-size: 150%;
    transition-duration: 0.4s;
    padding-right: 5px;
}

.navBarHomeText:hover {
    color: whitesmoke;
    text-decoration: none;
    font-size: 150%;
}

.navBarText {
    color: whitesmoke;
    text-decoration: none;
    font-size: 150%;
    transition-duration: 0.4s;
    padding-right: 5px;
}

.navBarText:hover {
    color: rgb(49, 204, 189);
    text-decoration: none;
    font-size: 150%;
}

#alignLeft {
    padding-left: 3%;
    width: 75%;
}

#alignRight {
    display: flex;
    justify-content: space-evenly;
    width: 50%;

}

.CENTER {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 70%;
    margin: auto;
}

#turqColor {
    color: rgb(49, 204, 189);
}

@media only screen and (max-width: 1350px) {
    #alignLeft {
        width: 50%;
        padding-right: 22%;
    }
}


/* Menu Icon Styling Here -------------------------------*/

#nav-icon1, #nav-icon2, #nav-icon3, #nav-icon4 {
    width: 60px;
    height: 45px;
    position: relative;
    margin: 50px auto;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
  }
  
  #nav-icon2 span {
    display: block;
    position: absolute;
    height: 9px;
    width: 50%;
    background: rgb(49, 204, 189);
    opacity: 1;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }
  
  #nav-icon2 span:nth-child(even) {
    left: 50%;
    border-radius: 0 9px 9px 0;
  }
  
  #nav-icon2 span:nth-child(odd) {
    left:0px;
    border-radius: 9px 0 0 9px;
  }
  
  #nav-icon2 span:nth-child(1), #nav-icon2 span:nth-child(2) {
    top: 0px;
  }
  
  #nav-icon2 span:nth-child(3), #nav-icon2 span:nth-child(4) {
    top: 18px;
  }
  
  #nav-icon2 span:nth-child(5), #nav-icon2 span:nth-child(6) {
    top: 36px;
  }
  
  #nav-icon2.open span:nth-child(1),#nav-icon2.open span:nth-child(6) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  
  #nav-icon2.open span:nth-child(2),#nav-icon2.open span:nth-child(5) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  
  #nav-icon2.open span:nth-child(1) {
    left: 5px;
    top: 7px;
  }
  
  #nav-icon2.open span:nth-child(2) {
    left: calc(50% - 5px);
    top: 7px;
  }
  
  #nav-icon2.open span:nth-child(3) {
    left: -50%;
    opacity: 0;
  }
  
  #nav-icon2.open span:nth-child(4) {
    left: 100%;
    opacity: 0;
  }
  
  #nav-icon2.open span:nth-child(5) {
    left: 5px;
    top: 29px;
  }
  
  #nav-icon2.open span:nth-child(6) {
    left: calc(50% - 5px);
    top: 29px;
  }
  

/* Mobile Menu Styling -----------------------------------*/

#hamNavSection {
    display:none;
    background-color: rgb(5, 5, 5);
    align-items: center;
    width: 100%;
    height: 08%;
}

#hamNavSection a.icon {
    color: white;
    background: black;
    display: block;
    left: 5;
    top: 0;
  }

#hamNavSection a:hover {
    background-color: #ddd;
    color: black;
  }

#hamModal {
    position: absolute;
    flex-direction: column;
    background: rgb(80, 80, 80, .85);
    border-radius: 5%;
    right:0;
    padding: 10px;
    z-index: 1100;
}

/* Navigation Bar Mobile Styling Here
@media only screen and (max-width: 000px) allows 
the screen settings to be styled for different sizes. In
the example below it will set these styles at 750px or smaller  */
@media only screen and (max-width: 750px) {

    #hamNavSection {
        display: flex;
    }

    #navBar {
        display: none;
    }
  }
  

/* Home Page Styling Here ----------------------------------*/
/* Might use this background image later instead. Saving URL here.. https://images2.alphacoders.com/840/thumb-1920-840511.jpg */
#background {
    background-image: url('https://images.pexels.com/photos/3395879/pexels-photo-3395879.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260');
    height: 92%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

#mainContainer {
    padding-top: 05%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#profileImg {
    width: 16vw;
    border-radius: 50%;
    border: 3px solid whitesmoke;
    transition-duration: 0.4s;
}

#profileImg:hover {
    transform: scale(1.1, 1.1);
    border-radius: 50%;
    border: 3px solid rgb(49, 204, 189);
}

.profTextColor {
    color: whitesmoke;
    transition-duration: 0.4s
}

.profTextColor:hover {
    color: rgb(49, 204, 189);
}

#socialMediaImgs {
    display: flex;
    width: 22vw;
    justify-content: space-between;
    margin-top: 1%;
}

#eMButton {
    border: none;
    background-color: transparent;
    outline: none;
}

#eMButton:hover {
    border: none;
    background-color: transparent;
    outline: none;
    cursor: pointer
}

.socialButtons {
    display: flex;
    height: 5vh;
    border: 2px solid whitesmoke;
    border-radius: 55%;
    transition-duration: 0.4s
}

.socialButtons:hover {
    display: flex;
    height: 5vh;
    border: 2px solid rgb(49, 204, 189);
    border-radius: 55%;
}

#emailDisplay {
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 40%;
    font-size: 100%;
    transform: translate(-50%, -50%);
    color: whitesmoke;
    text-shadow:
        -1px -1px 0 gray,
        1px -1px 0 gray,
        -1px 1px 0 rgb(20, 20, 20),
        1px 1px 0 rgb(20, 20, 20);
    background-color: rgba(0, 0, 0, 0.808);
    border-radius: 20px;
}

#emailDisplay > h1, #emailDisplay > h2, #emailDisplay > h3, #emailDisplay > h4, #emailDisplay > .contactMeLinkHome {
    padding-left: 5%;
}



#modalButton {
    background-color: rgb(235, 138, 11);
    border: none;
    color: whitesmoke;
    padding: 2px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    transition-duration: 0.4s;
    margin-left: 40%;
    margin-bottom: 3%;
}

#modalButton:hover {
    background-color: rgb(49, 204, 189);
    border: none;
    color: rgb(80, 80, 80);
    transform: scale(1.1, 1.1);
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
}

#noMargin {
    margin: 0;
    padding: 0;
}

/* Home Page Mobile Styling Here ----------------------------*/

@media only screen and (max-width: 750px) {
    
    #socialMediaImgs {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-top: 5%;
        width: 95%;
    }

    #emailDisplay {
        position: absolute;
        z-index: 1;
        left: 50%;
        top: 50%;
        font-size: 75%;
        transform: translate(-50%, -50%);
        color: whitesmoke;
        background-color: rgba(0, 0, 0, 0.808);
        text-shadow:
            -1px -1px 0 gray,
            1px -1px 0 gray,
            -1px 1px 0 rgb(20, 20, 20),
            1px 1px 0 rgb(20, 20, 20);
        width: 100%;
    } 

    #profileImg {
        width: auto;
        height: 45vh;
        border-radius: 50%;
        border: 3px solid whitesmoke;
        transition-duration: 0.4s;
    }
    
  }


/* About Page Styling Here -------------------------------*/
#flexContainer {
    display: flex;
    justify-content: center;
}

#profImageContainer {
    display: flex;
    justify-content: center;
    margin-right: 10%;
    margin-top: 07%;

}

#profileImgTwo {
    height: 60vh;
    border: 2px solid rgb(49, 204, 189);
    box-shadow: 10px 10px 5px #ccc;
    border-radius: 25px;
    transition-duration: 0.4s;
}

#profileImgTwo:hover {
    transform: scale(1.1, 1.1);
    border: 2px solid black;
    box-shadow: 10px 10px 5px #ccc;
    border-radius: 25px;
}

#profileContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 50%;
    margin-top: 07%;
    font-size: 110%;
    transition-duration: 0.4s;
}

#profileContainer:hover {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    transform: scale(1.1,1.1);
    margin-top: 07%;
  
}

#subContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50vw;
}

.subContainerTitles {
    text-decoration: underline;
    color: rgb(49, 204, 189);
}

#bottomRow {
    display: flex;
    width: 50vw;
    justify-content: center;
}


/* About Page Media Screen Styling Here ---------------------*/

@media only screen and (max-width: 750px) {
    #flexContainer {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    #subContainer {
        flex-direction: column;
        align-items: flex-start;
    }
    
    #profImageContainer {
        margin-right: 0;
    }
    
    #profileContainer {
        width:75%;
    }

}

/* Projects Page Styling Here ---------------------------*/
#projFlexContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 70%;
    margin: auto;
}

#titleContainer {
    display: flex;
    justify-content: center;
    margin-top: 3%;
}

.rows {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2%;
    width: 100%;
}

.halfWidth {
    width: 50%;
    border-top: 2px solid black;
    padding-top: 1%;
}

#green {
    color: green;
    transition-duration: 0.4s;
}

#green:hover {
    color: black;
}

#hubSpotOrng {
    color: #FF7A59;
}

#hubspotOrange {
    color: #FF7A59;
    transition-duration: 0.4s;
}

#hubspotOrange:hover {
    color:black;
}

.projectLinks {
    text-decoration: none;
    color: black;
    transition-duration: 0.4s;
}

.projectLinks:hover {
    text-decoration: none;
    color: rgb(49, 204, 189);
}

.projectImg {
    width: 100%;
    height: 28vh;
    border-radius: 5%;
    border: 2px solid rgb(49, 204, 189);
    box-shadow: 10px 10px 5px #ccc;
    border-radius: 15px;
    transition-duration: 0.4s;
}

.projectImg:hover {
    width: 100%;
    height: 28vh;
    border-radius: 5%;
    border: 2px solid black;
    box-shadow: 10px 10px 5px #ccc;
    border-radius: 15px;
}

.center {
    display: flex;
    justify-content: center;
}

.hubSpotButton {
    line-height: .50;
    border-radius: 20px;
    padding: 0px 20px;
    font-size: 75%;
    text-decoration: none;
    color: #FFFFFF;
    background-color: #FF7A59;
    transition-duration: 0.4s;
}

.hubSpotButton:hover {
    border-radius: 30px;
    padding: 0px 15px;
    font-size: 75%;
    text-decoration: none;
    color: black;
    background-color: #FF7A59;
}

.hubSpotButtonAlt {
    line-height: .50;
    border-radius: 20px;
    padding: 0px 20px;
    font-size: 75%;
    text-decoration: none;
    color: #FFFFFF;
    background-color: #FF7A59;
    transition-duration: 0.4s;
}

.hubSpotButtonAlt:hover {
    border-radius: 30px;
    padding: 0px 15px;
    font-size: 75%;
    text-decoration: none;
    color: black;
    background-color: #FF7A59;
}

/* Projects Page Mobile Styling Here --------------------- */
@media only screen and (max-width: 750px) {
    
    #rows {
        display: flex;
        justify-content: center;
        margin-top: 2%;
        width: 100%;
        font-size: 90%;
    }

    .projectImg {
        display: flex;
        width: 100%;
        height: 28vh;
        border-radius: 5%;
        border: 2px solid rgb(49, 204, 189);
        box-shadow: 10px 10px 5px #ccc;
        border-radius: 15px;
    }

    .halfWidth {
        width: 100%
    }
    
    .pushImg {
        margin-right: 0%;
    }

    .hubSpotButtonAlt {
        margin-top: .5rem;
    }
    
  }


/* Experience Page Styling ------------------------------*/
#iFrame {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
}

#center {
    display: flex;
    justify-content: center;
}

.resumeContainer {
    display: flex;
    flex-direction: column;
    max-width: 100vw;
    align-items: center;
    border-top: 2px solid black;
    padding-top: 5vh;
}

.resumeContainer > a {
    display: flex;
    justify-content: center;
}

.resumeImage1 {
    box-shadow: 4px 4px 6px 4px rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgb(49, 204, 189);
    transition-duration: .5s;
}

.resumeImage2 {
    margin-top: 3vh;
    box-shadow: 4px 4px 6px 4px rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgb(49, 204, 189);
    transition-duration: .5s;
}

.pageText1 {
    margin-top: 2vh;
    font-size: 60%;
}

.pageText2 {
    margin-top: 2vh;
    font-size: 60%;
}

@media only screen and (max-width: 750px) {

    .resumeContainer {
        padding-top: 3vh;
    }

    .resumeImage1 {
        max-width: 85%; 
    }
    
    .resumeImage2 {
        max-width: 85%; 
        margin-top: 2vh;
    }
    .pageText2 {
        margin-bottom: 3vh;
    }
}

/* Gallery Page --------------------------------------*/
.App {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 90vh;
}

#galleryHead {
    margin-bottom: 4%;
}

#rotating-img-carousel {
    display: flex;
    justify-content: space-around;
    align-items: center;
    display: flex;
    width: 100vw;
}

#img-carousel {
    position: relative;
    width: 100%;
    height: 60vh;
}

.lg-img {
    position: absolute;
    top: 0vh;
    left: 67vh;
    width: 35vw;
    height: auto;
    z-index: 10;
    border: 2px solid black
}

.sm-img-left {
    width: auto;
    height: 30vh;
    top: 10vh;
    z-index: 5;
    position: absolute;
    filter: blur(2px);
    left: 25vh;
    border: 2px solid black
}

.sm-img-right {
    width: auto;
    height: 30vh;
    top: 10vh;
    z-index: 5;
    position: absolute;
    filter: blur(2px);
    right: 25vh;
    border: 2px solid black
}

.xs-img-left {
    width: auto;
    height: 20vh;
    top: 15vh;
    position: absolute;
    filter: blur(3px);
    left: 0;
    border: 2px solid black
}

.xs-img-right {
    width: auto;
    height: 20vh;
    top: 15vh;
    position: absolute;
    filter: blur(3px);
    right: 0;
    border: 2px solid black
}

#nav-buttons {
    display: flex;
}

/* Gallery Mobile Friendly Version Here ----------------- */

@media only screen and (max-width: 1200px) {
    
    .App{
        transform: scale(.5);
        height:70vh;
    }

    #galleryHead {
        top:-40%;
        position: absolute;
        font-size: 150%;
    }

    #rotating-img-carousel {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        display: flex;
        width: 100vw;
    }
    
    .lg-img {
        position: absolute;
        top: 40vh;
        left: -10vh;
        width: 150vw;
        height: auto;
        z-index: 10;
        border: 2px solid black
    }

    .sm-img-left {
        width: auto;
        height: 30vh;
        top: 22vh;
        z-index: 5;
        position: absolute;
        filter: blur(2px);
        left: 3vh;
        border: 2px solid black
    }
    
    .sm-img-right {
        width: auto;
        height: 30vh;
        top: 85vh;
        z-index: 5;
        position: absolute;
        filter: blur(2px);
        left: 3vh;
        border: 2px solid black
    }
    
    .xs-img-left {
        width: auto;
        height: 20vh;
        top: 6vh;
        position: absolute;
        filter: blur(3px);
        left: 10vh;
        border: 2px solid black
    }
    
    .xs-img-right {
        width: auto;
        height: 20vh;
        top: 110vh;
        position: absolute;
        filter: blur(3px);
        left: 10vh;
        border: 2px solid black
    }

    #nav-buttons {
        display: flex;
        top: -05vh;
        position: absolute;
    }

    .img-carousel {
        margin-bottom: 10vh;
    }
  }

/* Beeswax Overview Page Styling Here ---------------- */

.beeRows {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 2%;
    width: 100%;
}

.halfWidthBW {
    width: 75%;
    padding-left:1.5%;
    padding-right:1.5%;
    padding-bottom:2.5%;
}

#beeswaxMastersContainer {
    margin-left:5rem;
    margin-right: 5rem;
    margin-bottom: .5rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 70%;
    margin: auto;
    border-top: 2px solid black;
}

.buttonsContainer {
    display: flex;
    justify-content: center;
    padding-top: 2rem;
}

.projectBttnPadd {
    margin-right:.5rem;
}

@media only screen and (max-width: 750px) {
    .buttonsContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-top: 2rem; 
    }

    #beeswaxMastersContainer {
        margin-left: none;
        margin-right: none;
        align-items: center;
        width: 95%;
    }

    .beeRows {
        display: flex;
        justify-content: center;
        margin-top: 2%;
        width: 100%;
        font-size: 90%;
        flex-direction: column;
    }

}

/* HubSpot Page Styling Here -------------------------- */

.projectButton {

    line-height: .50;
    border-radius: 20px;
    padding: 0px 20px;
    font-size: 75%;
    text-decoration: none;
    color: black;
    background-color: rgb(49, 204, 189);
    transition-duration: 0.4s;
}

.projectButton:hover {
    border-radius: 30px;
    padding: 0px 15px;
    font-size: 75%;
    text-decoration: none;
    color: white;
    background-color: rgb(49, 204, 189);
}


#projFlexContainerHS {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 70%;
    margin: auto;
}

.halfWidthTK {
    width: 50%;
    padding-left:1.5%;
    padding-right:1.5%;
    padding-bottom:2.5%;
}

.autoHeight {
    height: auto !important;
}

@media only screen and (max-width: 750px) {

    .halfWidthTK {
        width: 75%
    }
    
  }

/* Popup Styling Here -------------------------------- */ 

  .popup {
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: 25%;
    height: 35vh;
    width: 50vw;
    background:rgb(20, 20, 20, .95);
    color: white;
    border-radius: 40px;
    line-height: .5;
    z-index: 1000;
  }

  #popupButton {
    background-color: rgb(235, 138, 11);
    border: none;
    color: whitesmoke;
    padding: 2px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    transition-duration: 0.4s;
    margin-top: .5vh;
    z-index: 999999;
}

@media only screen and (max-width: 750px) {
    .popup {
        top: 15%;
        width: 95vw;
        height: 60vh;
        line-height: 1.5;
    }
    
    .popup > h2, .popup > h3, .popup > h4 {
        padding-left: 10px;
        padding-right: 10px;
    }
}

/* Contact Me Form & Link Styling */

#formContainer {
    margin-top: 10vh;
}

.contactMeLinkHome {
    text-decoration: underline;
    color: rgb(49, 204, 189);
    text-shadow: none;
    font-size: 150%;
    font-weight: bold;
}

.contactMeLink {
    text-decoration: underline;
    color: rgb(49, 204, 189);
    text-shadow: none;
    font-size: 140%;
    font-weight: bold;
    margin-bottom: 5vh;
}

.contactMeLinkPopup {
    text-decoration: underline;
    color: rgb(49, 204, 189);
    text-shadow: none;
    font-size: 85%;
    font-weight: bold;
    margin-bottom: 2vh;
}

.contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact-form > input, .contact-form > textarea {
    border: 2px solid black;
    border-radius: 4px;
    width: 25%;
}

.contact-form > input[type=text], .contact-form > textarea, .contact-form > input[type=email], .contact-form > input[type=tel]{
    background-color: rgb(49, 204, 189);
    color: white;
  }

  .contact-form > label {
      margin-top: 2vh;
  }

  .contact-form > input[type=submit] {
      margin-top: 2vh;
      width: 10vw;
      transition-duration: .25s;
  }

  .contact-form > input[type=submit]:hover {
    background-color: rgb(49, 204, 189);
  }

  .formPop {
    position: absolute;
    top: 25vh;
    right: 25vw;
    width: 50vw;
    height: 25vh;
    background:rgb(20, 20, 20, .95);
    color: white;
    border-radius: 40px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  #formPopButton {
    background-color: rgb(235, 138, 11);
    border: none;
    color: whitesmoke;
    padding: 2px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    transition-duration: 0.4s;
    margin-top: 2vh;
}

#formPopButton:hover {
    background-color: rgb(49, 204, 189);
    border: none;
    color: rgb(80, 80, 80);
    transform: scale(1.1, 1.1);
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
}

.centerFormPop {
    display: flex;
    justify-content: center;
    text-align: center;
}

@media only screen and (max-width: 750px) {
    #formContainer {
        margin-top: 5vh;
        padding: 0 15px 0 15px; 
    }

    .contact-form > input, .contact-form > textarea {
        width: 100%;
    }

    .contact-form > input[type="submit"] {
        width: 50vw;
    }

    .formPop {
        right:0%;
        width: 100%;
        height: 35vh;
    }
    .formPop > h1, .formPop > h2 {
        padding-left: 5%;
        padding-right: 5%;
    }
}